<template>
  <div class="createEditClient">
    <r-page-header
      class="createEditClient--header"
      previousroute="/admin/clients"
      :label="isEdit ? `Edit ${formData.name}` : 'Create Client'"
    />
    <r-error-message
      class="createEditClient--errormessage"
      :errors="[
        updatingClientError,
        creatingClientError,
        fetchBecomeClientError,
        fetchLogoutAllUsersError,
        fetchClientError
      ]"
      @close="onCloseError"
    />
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent
    >
      <r-text-field
        :rules="[rules.required, rules.maxSymbol, rules.minSymbol]"
        :value="formData.name"
        label="Name"
        placeholder="Client Name"
        style="width:100%"
        :validate-on-blur="true"
        hint="'Name' will be replaced by the value from BCR when the client logs in to Reviews"
        @change="(value) => onChange(value, 'name')"
      />
      <r-text-field
        :rules="[rules.required, rules.clientId]"
        class="mt-3"
        :value="formData.clientId"
        type="number"
        label="ClientID"
        placeholder="ClientID"
        style="width:100%"
        :disabled="isEdit"
        :validate-on-blur="true"
        @change="(value) => onChange(value, 'clientId')"
      />
      <r-text-field
        :value="formData.maxUrls"
        label="Max. URLs"
        type="number"
        :rules="[rules.min]"
        placeholder="Max. URLs"
        style="width:100%"
        :validate-on-blur="true"
        @change="(value) => onChange(value, 'maxUrls')"
      />
      <r-text-field
        v-if="isEdit"
        :disabled="true"
        :value="formData.urlsUsed"
        label="Urls used"
        type="number"
        style="width:100%"
      />
      <r-text-field
        :value="formData.scrapeCadence"
        label="Scrape cadence"
        type="number"
        placeholder="Scrape Cadence"
        :rules="[rules.min, rules.max]"
        style="width:100%"
        :validate-on-blur="true"
        @change="(value) => onChange(value, 'scrapeCadence')"
      />
      <span>Domains</span>
      <r-select
        v-if="totalDomainsWithoutFilter"
        :selected-records-count="selectedRecordsCount"
        :excluded-records-count="excludedDomainIds.length"
        :multiple="true"
        :total-records="totalDomainsWithoutFilter"
        :items="hostnameItems"
        :infinite="true"
        :show-select-all="true"
        :selected-items-for-chip="selectedDomains"
        @on:get-more="onGetMoreItems"
        @change:search="onChangeSearch"
        @on:select-all="onSelectAllDomains"
        @on:deselect="onDeselect"
        @on:select="onSelect"
      />
      <span class="d-block mt-3">App features</span>
      <r-select
        multiple
        :show-search="false"
        :items="appFeatureItems"
        @change="(value) => onChange(value, 'appFeatures')"
      />

      <div class="ml-n1">
        <v-checkbox
          v-model="formData.suspended"
          class="mt-3 pb-0 mb-0"
          color="#239EDB"
          dense
          label="Suspended"
        />
        <v-checkbox
          v-model="formData.enrichmentPipeline"
          class="pt-0"
          color="#239EDB"
          dense
          label="Enriched data"
        />
      </div>

      <v-row class="mt-3">
        <v-col v-if="isEdit">
          <r-button
            class="mr-2"
            :loading="fetchingBecomeClient"
            label="Become"
            @click="onClickBecomeClient"
          />
          <r-button
            :loading="fetchingLogoutAllUsers"
            class="createEditClient--logout-all-btn"
            color="red"
            label="Log out all users"
            type="button"
            @click="onClickLogoutAllUsers"
          />
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <r-button
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="onClickCancel"
          />
          <r-button
            :label="isEdit ? 'Update' :'Create'"
            @click="onClickApply"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RPageHeader from '@/components/library/molecules/RPageHeader.vue'
import RTextField from '@/components/library/molecules/RTextField.vue'
import RSelect from '@/components/library/molecules/RSelect.vue'
import RButton from '@/components/library/atoms/RButton.vue'
import RErrorMessage from '@/components/library/atoms/RErrorMessage.vue'
import { generateParams } from '@/utils/store'

const DEFAULT_PAGE = 1

export default {
  name: 'CreateEditClient',
  components: {
    RErrorMessage,
    RButton,
    RSelect,
    RTextField,
    RPageHeader,
  },
  data() {
    return {
      pageTitle: 'Create Client',
      valid: false,
      isEdit: false,
      rules: {
        min: (v) => v > 0 || 'min 1',
        max: (v) => v < 366 || 'max 365',
        required: (v) => v.length > 0 || 'Field is required',
        maxSymbol: (v) => v.length < 256 || 'max 255',
        minSymbol: (v) => v.length > 0 || 'min 1',
        clientId: (v) => (v > 0 && v <= 2147483647) || 'Client Id should be between 1 and 2147483647',
      },
      formData: {
        name: '',
        maxUrls: 50,
        scrapeCadence: 1,
        domainIds: [],
        appFeatures: [],
        suspended: false,
        enrichmentPipeline: true,
        clientId: undefined,
        excludedDomainIds: [],
      },
      domainsSearchValue: '',
      allDomainsSelected: false,
      excludedDomainIds: [],
      selectedDomains: [],
      totalDomainsWithoutFilter: 0,
    }
  },
  computed: {
    ...mapState('admin', [
      'appFeatures',
      'domains',
      'creatingClient',
      'creatingClientError',
      'updatingClient',
      'updatingClientError',
      'fetchingBecomeClient',
      'fetchBecomeClientError',
      'fetchingLogoutAllUsers',
      'fetchLogoutAllUsersError',
      'fetchClientError',
      'pageSizeDomains',
      'pageDomains',
      'totalDomains',
    ]),
    hostnameItems() {
      const { domainIds } = this.formData
      return this.domains.map(({ _id, name }) => {
        const isSelected = domainIds.includes(_id)
        return {
          label: name,
          value: _id,
          selected: (!this.allDomainsSelected && isSelected) || (this.allDomainsSelected
              && !this.excludedDomainIds.includes(_id)),
        }
      })
    },
    appFeatureItems() {
      const { appFeatures } = this.formData
      return this.appFeatures.map(({ _id, displayName }) => {
        const isSelected = appFeatures.includes(_id)

        return {
          label: displayName,
          value: _id,
          selected: isSelected,
        }
      })
    },
    selectedRecordsCount() {
      if (this.allDomainsSelected) {
        return this.totalDomains
      }

      return this.formData.domainIds.length
    },
  },
  watch: {
    creatingClient(newValue, oldValue) {
      if (oldValue === true && newValue === false && !this.creatingClientError) {
        this.$router.push('/admin/clients')
      }
    },
    updatingClient(newValue, oldValue) {
      if (oldValue === true && newValue === false && !this.updatingClientError) {
        this.$router.push('/admin/clients')
      }
    },
    domains(newDomains, oldDomains) {
      if (this.allDomainsSelected) {
        const allDomains = [...oldDomains, ...newDomains]
        this.selectedDomains = Array.from(new Set(allDomains.map((a) => a._id)))
          .map((itemValue) => allDomains.find((a) => a._id === itemValue))
          .map(({ _id, name }) => ({
            label: name,
            value: _id,
          })).filter(({ value }) => !this.excludedDomainIds.includes(value))
      }
    },
  },
  async beforeMount() {
    const { clientId } = this.$route.params
    const params = { page: this.pageDomains, pageSize: this.pageSizeDomains }
    await this.getDomains(params)
    this.totalDomainsWithoutFilter = this.totalDomains

    if (!this.appFeatures.length) {
      await this.fetchAppFeatures()
    }

    if (clientId) {
      this.isEdit = true

      const client = await this.fetchClient({ clientId })

      const {
        maxUrls = 50,
        name,
        scrapeCadence = 1,
        suspended = false,
        enrichmentPipeline = true,
        domainIds = [],
        appFeatures = [],
        excludedDomainIds = [],
        urlsUsed = 0,
      } = client

      this.formData = {
        maxUrls,
        name,
        scrapeCadence,
        suspended,
        enrichmentPipeline,
        clientId,
        domainIds,
        appFeatures,
        excludedDomainIds,
        urlsUsed,
      }
      if (domainIds.length === this.totalDomainsWithoutFilter) {
        this.allDomainsSelected = true
      }
      // in case we have selected domains which doesn't exist in domains list
      const ids = domainIds.filter((id) => !this.domains.find(({ _id }) => id === _id))
      if (ids.length) {
        await this.getDomains({ domainIds: ids, isInfinityScroll: true })
      }

      this.selectedDomains = this.domains
        .map(({ _id, name: domainName }) => domainIds.includes(_id) && ({
          label: domainName,
          value: _id,
        })).filter(Boolean)
    }
  },
  beforeDestroy() {
    this.clearErrors()
  },
  methods: {
    ...mapActions('admin', [
      'fetchAppFeatures',
      'createClient',
      'updateClient',
      'fetchDomains',
      'fetchClient',
      'clearErrors',
      'becomeClient',
      'logoutAllUsers',
    ]),
    ...mapActions('dashboards', {
      setPageDashboards: 'setPage',
    }),
    ...mapActions('alerts', {
      setPageAlerts: 'setPage',
    }),
    ...mapActions('brands', {
      setPageBrands: 'setPage',
    }),
    ...mapActions('categories', {
      setPageCategories: 'setPage',
    }),
    ...mapActions('products', {
      setPageProducts: 'setPage',
    }),
    ...mapActions('tags', {
      setPageTags: 'setPage',
    }),
    ...mapActions('urls', {
      setPageUrls: 'setPage',
    }),
    onChange({ value }, field) {
      if (['appFeatures'].includes(field)) {
        this.formData[field] = value.map(({ value: itemValue }) => itemValue)
        return
      }
      this.formData[field] = value
    },
    onClickApply() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.allDomainsSelected) {
        this.formData.domainIds = ['all']
        this.formData.excludedDomainIds = this.excludedDomainIds
      }
      if (this.isEdit) {
        const { urlsUsed: _, ...data } = this.formData
        this.updateClient(data)
        return
      }
      this.createClient(this.formData)
    },
    onClickCancel(e) {
      e.preventDefault()

      this.$router.push('/admin/clients')
    },
    resetToPageOne() {
      this.setPageDashboards({ page: DEFAULT_PAGE })
      this.setPageAlerts({ page: DEFAULT_PAGE })
      this.setPageBrands({ page: DEFAULT_PAGE })
      this.setPageCategories({ page: DEFAULT_PAGE })
      this.setPageProducts({ page: DEFAULT_PAGE })
      this.setPageTags({ page: DEFAULT_PAGE })
      this.setPageUrls({ page: DEFAULT_PAGE })
    },
    async onClickBecomeClient(e) {
      e.preventDefault()

      const { clientId } = this.formData
      await this.becomeClient({ clientId })

      if (!this.fetchBecomeClientError) {
        this.resetToPageOne()
        this.$router.push('/data')
      }
    },
    async onClickLogoutAllUsers() {
      const { clientId } = this.formData
      await this.logoutAllUsers({ clientId })
    },
    onCloseError() {
      this.clearErrors()
    },
    onGetMoreItems() {
      const pageParams = generateParams('admin')
      if (!pageParams) {
        return
      }
      const params = {
        isInfinityScroll: true,
        pageSize: this.pageSizeDomains,
        search: this.domainsSearchValue,
        ...pageParams,
      }
      this.getDomains(params)
    },
    onChangeSearch({ value }) {
      this.domainsSearchValue = value
      this.getDomains({ search: value, page: 1, pageSize: this.pageSizeDomains })
    },
    onSelectAllDomains(value) {
      this.allDomainsSelected = value
      this.excludedDomainIds = []
      this.selectedDomains = value ? this.hostnameItems : []
      if (!value) {
        this.formData.domainIds = []
      }
    },
    onDeselect({ selectedItem }) {
      if (this.allDomainsSelected) {
        this.excludedDomainIds = [...this.excludedDomainIds, selectedItem.value]
      }
      this.selectedDomains = this.selectedDomains.filter((it) => it.value !== selectedItem.value)
      this.formData.domainIds = this.selectedDomains.map(({ value }) => value)
    },
    onSelect({ selectedItem }) {
      if (this.allDomainsSelected) {
        this.excludedDomainIds = this.excludedDomainIds
          .filter((domainId) => domainId !== selectedItem.value)
      }
      this.selectedDomains.push(selectedItem)
      this.formData.domainIds = this.selectedDomains.map(({ value: itemValue }) => itemValue)
    },
    getDomains(params) {
      const p = { sort: 'name', sortDir: 'asc', ...params }
      return this.fetchDomains(p)
    },
  },
}
</script>

<style scoped>
.createEditClient--header {
  margin-bottom: 12px !important;
  margin-top: 10px;
}
.createEditClient--logout-all-btn {
  color: white;
}
</style>
